import React from "react";
import { Link } from "react-router-dom";
function Header() {
    return (
        <header className="text-disable w-full bg-background body-font sticky top-0 z-50 shadow flex flex-col md:flex-row justify-between bg-main font-bold">
            <div className=" md:w-1/3 flex justify-center items-center">
                <Link
                    to="/"
                    className="title-font text-lg md:text-2xl  text-center text-white break-keep py-4 flex gap-1 items-center"
                >
                    <img
                        src="/images/top-image/Laos.png"
                        className="w-6 object-contain"
                        alt="ラオスの国旗"
                    />
                    福岡ラオス友好協会
                    <img
                        src="/images/top-image/Japan.png"
                        className="w-6 object-contain"
                        alt="日本の国旗"
                    />
                </Link>
            </div>
            <div className="md:w-2/3 flex justify-center items-center gap-4 md;gap-8 text-base md:text-lg flex-wrap">
                <Link
                    to="/association"
                    className="hover:text-white hover:bg-white hover:bg-opacity-10  p-2 md;p-4  text-center break-keep"
                >
                    協会について
                </Link>
                <Link
                    to="/news"
                    className="hover:text-white hover:bg-white hover:bg-opacity-10 p-2 md;p-4  text-center break-keep"
                >
                    お知らせ
                </Link>
                {/* 下位ページに事業とメンバーと会則を入れる */}
                <Link
                    to="/article"
                    className="hover:text-white hover:bg-white hover:bg-opacity-10 p-2 md;p-4  text-center break-keep"
                >
                    会報誌
                </Link>
                <Link
                    to="/laos"
                    className="hover:text-white hover:bg-white hover:bg-opacity-10 p-2 md;p-4  text-center break-keep"
                >
                    ラオスについて
                </Link>
                <Link
                    to="/ten-years"
                    className="hover:text-white hover:bg-white hover:bg-opacity-10 p-2 md;p-4  text-center break-keep"
                >
                    １０年のあゆみ
                </Link>
            </div>
        </header>
    );
}
export default Header;
