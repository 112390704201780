import React from "react";
import { Link } from "react-router-dom";
import AssociationMenu from "../templates/sidemenu/AssociationMenu";
const AssociationPage: React.FC = () => {
    // ユーザーの画面サイズを取得
    const [userView, setUserView] = React.useState(
        sessionStorage.getItem("userView")
    );

    if (userView === "") {
        return <></>;
    }
    return (
        // ニュース一覧を表示
        //  ページのタイトルは左上に表示
        // 日時、タイトルを表示
        // linkで囲って、クリックすると詳細ページに飛ぶ
        <div className="base-margin">
            {/* sp版の方では<AssociationMenu />を表示 */}
            {userView === "sp" && <AssociationMenu />}
            <h1 className="text-4xl font-bold text-start my-8">協会について</h1>
            <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col md:flex-row justify-between gap-16 w-full">
                    {/* pc版の方では<AssociationMenu />を表示 */}
                    {userView === "pc" && <AssociationMenu />}
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col w-full mb-8">
                            <div className="my-2">
                                <p className="text-xl font-bold border-b-2 p-1 pr-8">
                                    当協会の成り立ち
                                </p>
                            </div>
                            <p className="text-lg m-2 mt-0 break-all">
                                福岡ラオス友好協会は2010年4月に設立されました。
                                <br />
                                それは、チャイナプラスワンとして発展著しいタイ・ベトナムに続き発展しているカンボジア・ラオス・ミャンマーの研究をしようと、2008年7月インドシナ半島研究会を立ち上げ勉強会を開き、その仕上げに福岡貿易会設立50周年事業として2009年2月、経済視察団を派遣した事から始まりました。
                                <br />
                                視察団が最後に訪問したのが、ラオスの首都ビエンチャンと古都ルアンパパーンでした。
                                <br />
                                毎朝繰り広げられる托鉢、毎夜路上で開かれるナイトマーケット、街を少し離れると紙漉き・機織り・焼酎を作っている村々、特にメコンの支流で無邪気に水遊びに興じる子ども達と水辺までは幅1メートルぐらいの竹の橋、古き良き日本の田園風景を思い起こしました。
                                <br />
                                帰福し、ラオスに何かできることはないかと声が上がり、福岡ラオス倶楽部設立に取り掛かった時、在日本ラオス人民民主共和国大使館のシートン特命全権大使が来福され、友好協会設立に至りました。
                                <br />
                                友好協会はラオスとの経済・文化交流を目指し、ラオスへの定期的訪問、ラオスの子ども達に図書室・図書の寄贈、ラオスの医療支援の為医療機器・ベッド等の寄贈、ラオス南部のダム決壊による大水害への義援金寄贈等の支援を行っています。
                                <br />
                            </p>
                        </div>
                        <Link
                            to={"/ten-years"}
                            className="flex flex-col w-full "
                        >
                            <div className="my-2">
                                <p className="text-2xl font-bold p-1 border-b-2 pr-8">
                                    １０年のあゆみ
                                </p>
                            </div>
                            <p className="m-2 mt-0 text-xl hover:underline w-full">
                                当協会１０年のあゆみを振り返る特設ページを作成しました。
                                <br />
                                詳細は<span className="font-bold">こちら</span>
                            </p>
                            {/* <img
                                className="mx-4 mb-4"
                                src="images/ten-years/ten-years-page-1.png"
                            /> */}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssociationPage;
