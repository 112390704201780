import React, { useState } from "react";
import { Link } from "react-router-dom";
const TenYearsPage: React.FC = () => {
    // オーバーレイ用のステートを作成
    let [overlay, setOverlay] = useState<boolean>(false);
    // 画像の配列を作成
    const images = [
        { src: "images/ten-years/ten-years-page-1.jpg", alt: "1ページ目" },
        { src: "images/ten-years/ten-years-page-2.jpg", alt: "2ページ目" },
        { src: "images/ten-years/ten-years-page-3.jpg", alt: "3ページ目" },
        { src: "images/ten-years/ten-years-page-4.jpg", alt: "4ページ目" },
        { src: "images/ten-years/ten-years-page-5.jpg", alt: "5ページ目" },
        { src: "images/ten-years/ten-years-page-6.jpg", alt: "6ページ目" },
        { src: "images/ten-years/ten-years-page-7.jpg", alt: "7ページ目" },
        { src: "images/ten-years/ten-years-page-8.jpg", alt: "8ページ目" },
        { src: "images/ten-years/ten-years-page-9.jpg", alt: "9ページ目" },
        { src: "images/ten-years/ten-years-page-10.jpg", alt: "10ページ目" },
        { src: "images/ten-years/ten-years-page-11.jpg", alt: "11ページ目" },
        { src: "images/ten-years/ten-years-page-12.jpg", alt: "12ページ目" },
        { src: "images/ten-years/ten-years-page-13.jpg", alt: "13ページ目" },
        { src: "images/ten-years/ten-years-page-14.jpg", alt: "14ページ目" },
        { src: "images/ten-years/ten-years-page-15.jpg", alt: "15ページ目" },
        { src: "images/ten-years/ten-years-page-16.jpg", alt: "16ページ目" },
    ];
    // 画像の配列の要素番号を保持するステートを作成
    let [index, setIndex] = useState<number>(0);

    // 画像をクリックしたときにオーバーレイ表示する処理
    function onClickImage(index: number) {
        // 画面サイズが小さいときはオーバーレイ表示しない
        if (window.innerWidth < 640) return;
        setIndex(index);
        setOverlay(true);
    }
    // オーバーレイ表示を閉じる処理(ovelayかcloseをクリックしたときのみ発火)
    function onClickOverlay() {
        setOverlay(false);
    }
    // 次の画像を表示する処理
    function onClickNext(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        // イベントの伝播を止める
        e.stopPropagation();
        // 画像の配列の次の要素を表示する,maxを超えるときはmaxを表示する
        setIndex(index + 1 > images.length - 1 ? images.length - 1 : index + 1);
    }

    // 前の画像を表示する処理
    function onClickPrev(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        // イベントの伝播を止める
        e.stopPropagation();
        // 画像の配列の前の要素を表示する-,1になるときは0を表示する
        setIndex(index - 1 < 0 ? 0 : index - 1);
    }

    return (
        // ニュース一覧を表示
        //  ページのタイトルは左上に表示
        // 日時、タイトルを表示
        // linkで囲って、クリックすると詳細ページに飛ぶ
        <div className="base-margin">
            <h1 className="text-4xl font-bold text-start my-8">
                １０年のあゆみ
            </h1>
            <div className="flex flex-col justify-center items-center">
                <div className="m-4 mb-0 flex flex-wrap w-full gap-2 md:gap-8 justify-evenly">
                    {/* imagesから画像を繰り返し表示 */}
                    {images.map((image, i) => (
                        <img
                            className="w-full md:w-60 mb-8 border onbject-cover hover:bg-gray-100"
                            onClick={() => onClickImage(i)}
                            src={image.src}
                            alt={image.alt}
                            key={i}
                        />
                    ))}
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center md:mt-16">
                <a
                    href="/images/ten-years/ten-years.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        PDFをダウンロード
                    </button>
                </a>
            </div>

            {/* 画像をクリックしたときにオーバーレイ表示する , フラグはoverlay*/}
            {overlay && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex items-center justify-center"
                    onClick={onClickOverlay}
                    id="overlay"
                >
                    <div className="relative bg-gray-900 bg-opacity-50 rounded-lg  w-4/6">
                        <div className="absolute right-4 top-4 m-4 cursor-pointer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-6 h-6 fill-white cursor-pointer"
                                onClick={onClickOverlay}
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>
                        <img
                            className="py-8 px-56 object-contain"
                            src={images[index].src}
                            alt={images[index].alt}
                        />
                        <div className="absolute h-full w-full z-10 opacity-70 top-0">
                            <div className="flex px-8 justify-between items-center h-full w-full">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-12 h-12 bg-gray-500 p-2 rounded-full cursor-pointer fill-white"
                                    onClick={(e) => onClickPrev(e)}
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                                        clip-rule="evenodd"
                                    />
                                </svg>

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-12 h-12 bg-gray-500 p-2 rounded-full cursor-pointer fill-white"
                                    onClick={(e) => onClickNext(e)}
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M3.75 12a.75.75 0 01.75-.75h13.19l-5.47-5.47a.75.75 0 011.06-1.06l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H4.5a.75.75 0 01-.75-.75z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TenYearsPage;
