import React from "react";
const CarouselComponents: React.FC = () => {
    const images = [
        { src: "images/top-image/top-image-1.jpg", alt: "川と日の出" },
        { src: "images/top-image/top-image-2.jpg", alt: "タート ルアン" },
        { src: "images/top-image/top-image-3.jpg", alt: "ブッダパーク" },
        { src: "images/top-image/top-image-4.jpg", alt: "托鉢の様子" },
        {
            src: "images/top-image/top-image-5.jpg",
            alt: "ワット・マイ・スワナ・プーン・ラーム",
        },
        {
            src: "images/top-image/top-image-6.jpg",
            alt: "プラーサート・ワット・プー",
        },
    ];
    // 画像の配列の何番目を表示するか
    const [index, setIndex] = React.useState(0);

    // 次の画像を表示する処理
    function onClickNext() {
        // 画像の配列の次の要素を表示する,maxを超えるときは0を表示する
        setIndex(index + 3 > images.length - 1 ? 0 : index + 3);
    }

    // 前の画像を表示する処理
    function onClickPrev() {
        // 画像の配列の前の要素を表示する-,1になるときはmaxを表示する
        setIndex(index - 3 < 0 ? images.length - 3 : index - 3);
    }

    // 7秒ごとに画像を切り替える処理
    React.useEffect(() => {
        const timer = setInterval(() => {
            onClickNext();
        }, 7000);
        return () => clearInterval(timer);
    }, [index]);

    return (
        <>
            <div className="relative w-full h-32 md:h-64 lg:h-96 -mt-8">
                <div className="absolute top-0 h-full w-full">
                    <div className="flex justify-center w-full h-full gap-2">
                        {images.map((image, i) => (
                            <>
                                <img
                                    key={i}
                                    className={`mb-8 object-contain h-full
                                ${
                                    i >= index && i <= index + 2
                                        ? "block"
                                        : "hidden"
                                }
                                `}
                                    id="top-image"
                                    src={image.src}
                                    alt={image.alt}
                                />
                            </>
                        ))}
                    </div>
                </div>
                <div className="absolute top-0 h-full w-full z-10 opacity-70">
                    <div className="flex px-4 justify-between items-center h-full w-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-6 md:w-6 md:h-6  lg:w-12 lg:h-12 bg-gray-500 p-2 rounded-full cursor-pointer fill-white"
                            onClick={onClickPrev}
                        >
                            <path
                                fill-rule="evenodd"
                                d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                                clip-rule="evenodd"
                            />
                        </svg>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-6 md:w-6 md:h-6  lg:w-12 lg:h-12 bg-gray-500 p-2 rounded-full cursor-pointer fill-white"
                            onClick={onClickNext}
                        >
                            <path
                                fill-rule="evenodd"
                                d="M3.75 12a.75.75 0 01.75-.75h13.19l-5.47-5.47a.75.75 0 011.06-1.06l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H4.5a.75.75 0 01-.75-.75z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                </div>
                {/* 下に画像の個数分●をおく */}
                <div className="absolute bottom-4 left-0 w-full flex gap-4 justify-center opacity-70">
                    {images.map(
                        (_, i) =>
                            // 配列の数/3の時に●を表示する
                            i % 3 === 0 && (
                                <p
                                    key={i}
                                    className={`${
                                        index === i
                                            ? "text-white"
                                            : "text-gray-500"
                                    } text-lg md:text-xl lg:text-2xl mx-1 cursor-pointer`}
                                    onClick={() => setIndex(i)}
                                >
                                    ●
                                </p>
                            )
                    )}
                </div>
            </div>
        </>
    );
};

export default CarouselComponents;
