import React from "react";
import { Link } from "react-router-dom";
const Error404: React.FC = () => {
    return (
        // ニュース一覧を表示
        //  ページのタイトルは左上に表示
        // 日時、タイトルを表示
        // linkで囲って、クリックすると詳細ページに飛ぶ
        <div className="base-margin flex flex-col items-center justify-center mt-16 md:mt-32">
            <h1 className="text-4xl md:text-8xl font-bold">404</h1>
            <p className="text-lg md:text-2xl font-bold">
                ページが見つかりませんでした
            </p>
        </div>
    );
};

export default Error404;
