import React from "react";
import AssociationMenu from "../templates/sidemenu/AssociationMenu";
const MemberPage: React.FC = () => {
    const members = [
        "㈱赤坂綜合事務所",
        "朝陽産業㈱",
        "㈱アルシュ",
        "㈱石蔵商店",
        "㈱イックス",
        "インターナショナルエクスプレス㈱福岡支店",
        "岡部機械工業㈱",
        "㈱カシマ製作所",
        "（一社）九州経済連合会",
        "九州電力㈱",
        "光栄テクノシステム㈱",
        "河野産業㈱",
        "㈱極東フーズコーポレーション",
        "山九㈱九州エリア開発営業部",
        "㈱シグマイン",
        "㈱ＪＥＣ",
        "㈱ＪＴＢ 福岡支店",
        "㈱GMAjapan ",
        "㈱正興電機製作所",
        "太平洋貿易㈱",
        "㈱田中三次郎商店",
        "津田ホールディングス㈱",
        "㈱テレビ西日本",
        "㈱トクスイコーポレーション",
        "㈱内藤工務店",
        "㈱中特ホールデイング",
        "中村工業㈱",
        "㈲中牟田大郷",
        "西研グラフィックス㈱",
        "西鉄旅行㈱福岡統括支店",
        "日本通運㈱福岡海運支店",
        "（一社）日本福祉予防医療協会",
        "㈱ネッツ",
        "㈱パナックスメデイア",
        "㈱久原本家グループ本社",
        "㈱ヒデトレーディング",
        "㈱ビー・エス・エス",
        "フォーサイト㈱",
        "福岡大同青果㈱",
        "㈱福岡ニット",
        "フクハン㈱",
        "㈱ふくや",
        "㈲まるよし",
        "三井物産㈱九州支社",
        "三菱商事㈱九州支社",
        "㈱矢野特殊自動車",
        "㈱ユウシステム",
    ];
    // ユーザーの画面サイズを取得
    const [userView, setUserView] = React.useState(
        sessionStorage.getItem("userView")
    );

    if (userView === "") {
        return <></>;
    }
    return (
        // メンバーページはメンバーの情報を表示する

        // タイトル
        // 役員と法人会員の情報
        <div className="base-margin">
            {/* sp版の方では<AssociationMenu />を表示 */}
            {userView === "sp" && <AssociationMenu />}
            <div className="flex flex-col md:flex-row my-8">
                <h1 className="text-4xl font-bold text-start ">会員一覧</h1>
                <div className="text-2xl font-medium">(令和5年度版)</div>
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-16 w-full">
                {/* pc版の方では<AssociationMenu />を表示 */}
                {userView === "pc" && <AssociationMenu />}
                <div className="w-full">
                    <table className="w-full mb-8">
                        <thead className="text-lg font-bold">
                            <tr className="w-full flex justify-end">
                                <th className="p-1">(敬称略 五十音順)</th>
                            </tr>
                            <tr className="w-full flex">
                                <th className="p-1">法人会員</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member) => (
                                <tr>
                                    <td className="p-1">・{member}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="text-base">
                        <th className="text-lg font-bold">個人会員</th>
                        <div className="text-base mb-4">
                            浅田雅宏、尾花智恵、甲斐敏洋、金子タカ子、柴山三鈴、末松和子、芹田智、崎村陸、田中悟
                            <br />
                            高山和代、多賀谷通、中村哲、西村尚幸、原田昌行、畑間啓子、橋本冨喜子、村川正光、
                            山下憲一
                            <br />
                            他8名
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberPage;
