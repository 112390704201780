import React from "react";
import { Link } from "react-router-dom";
import CarouselComponents from "../components/CarouselComponents";
const HomePage: React.FC = () => {
    // お知らせ
    const news = [
        {
            title: "ラオスニュースNo.26が発行されました！",
            date: "2023/08/29",
            link: "/news/article4",
        },
        {
            title: "微笑みの国・ラオスセミナーについて。",
            date: "2023/07/03",
            link: "/news/article3",
        },
        {
            title: "理事会・総会及び会員交流会の開催について",
            date: "2023/05/21",
            link: "/news/article2",
        },
        {
            title: "ラオスニュースNo.25が発行されました！",
            date: "2023/02/10",
            link: "/news/article1",
        },
    ];
    return (
        <>
            <CarouselComponents></CarouselComponents>
            <div className="flex flex-col justify-center items-center base-margin">
                <div className="flex flex-col w-full my-8">
                    <div className="my-2">
                        <p className="text-xl font-bold border-b-2 p-1 pr-8">
                            福岡ラオス友好協会とは
                        </p>
                    </div>
                    <p className="text-lg m-2 mt-0 break-all">
                        福岡ラオス友好協会は2010年4月に設立されました。
                        <br />
                        当協会はラオスとの経済・文化交流を目指し、
                        ラオスへの定期的訪問、ラオスの子ども達に図書室・図書の寄贈、ラオスの医療機器・
                        ベッド等の寄贈、ラオス南部のダム決壊による大水害への義援金寄贈等の支援を行っています。
                    </p>
                </div>
                <div className="flex flex-col md:flex-row justify-between gap-16 w-full">
                    <div className=" flex flex-col  w-full">
                        <div className="my-2">
                            <p className="text-2xl font-bold p-1  border-b-2 pr-8">
                                お知らせ
                            </p>
                        </div>
                        <div className="flex flex-col gap-2 text-sm md:text-lg">
                            {/* newsから生成 */}
                            {news.map((item) => (
                                <Link
                                    to={item.link}
                                    className="ml-4 grid grid-flow-col-dense grid-cold-4 py-2 gap-4 border-b hover:bg-gray-100"
                                >
                                    <p>{item.date}</p>
                                    <p className="col-span-3">{item.title}</p>
                                </Link>
                            ))}
                            <Link
                                to={"/news"}
                                className="ml-4 grid grid-flow-col-dense grid-cold-4 py-2 gap-4 text-end"
                            >
                                <p className="col-span-3 hover:underline">
                                    お知らせ一覧を見る
                                </p>
                            </Link>
                        </div>
                    </div>
                    <Link to={"/ten-years"} className="flex flex-col w-full ">
                        <div className="my-2">
                            <p className="text-2xl font-bold p-1 border-b-2 pr-8">
                                １０年のあゆみ
                            </p>
                        </div>
                        <img
                            className="mx-4 mb-4"
                            src="images/ten-years/ten-years-page-1.jpg"
                            alt="十年の歩み表紙"
                        />
                    </Link>
                </div>
            </div>
        </>
    );
};

export default HomePage;
