import React from "react";
import { Link } from "react-router-dom";
const LaosPage: React.FC = () => {
    // サムネイル画像, タイトル, pdfのリンクを配列で持つ
    // 内容はimages/laos-newsにあるthumbnail
    // pdfはimages/laos-newsにあるpdf
    const articles = [
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-26.jpg",
            title: "ラオスニュース No.26",
            link: "/images/laos-news/laos-news-26.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-25.jpg",
            title: "ラオスニュース No.25",
            link: "/images/laos-news/laos-news-25.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-24.jpg",
            title: "ラオスニュース No.24",
            link: "/images/laos-news/laos-news-24.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-23.jpg",
            title: "ラオスニュース No.23",
            link: "/images/laos-news/laos-news-23.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-21.jpg",
            title: "ラオスニュース No.21",
            link: "/images/laos-news/laos-news-21.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-20.jpg",
            title: "ラオスニュース No.20",
            link: "/images/laos-news/laos-news-20.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-18.jpg",
            title: "ラオスニュース No.18",
            link: "/images/laos-news/laos-news-18.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-16.jpg",
            title: "ラオスニュース No.16",
            link: "/images/laos-news/laos-news-16.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-15.jpg",
            title: "ラオスニュース No.15",
            link: "/images/laos-news/laos-news-15.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-14.jpg",
            title: "ラオスニュース No.14",
            link: "/images/laos-news/laos-news-14.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-13.jpg",
            title: "ラオスニュース No.13",
            link: "/images/laos-news/laos-news-13.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-12.jpg",
            title: "ラオスニュース No.12",
            link: "/images/laos-news/laos-news-12.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-11.jpg",
            title: "ラオスニュース No.11",
            link: "/images/laos-news/laos-news-11.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-10.jpg",
            title: "ラオスニュース No.10",
            link: "/images/laos-news/laos-news-10.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-9.jpg",
            title: "ラオスニュース No.9",
            link: "/images/laos-news/laos-news-9.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-8.jpg",
            title: "ラオスニュース No.8",
            link: "/images/laos-news/laos-news-8.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-7.jpg",
            title: "ラオスニュース No.7",
            link: "/images/laos-news/laos-news-7.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-6.jpg",
            title: "ラオスニュース No.6",
            link: "/images/laos-news/laos-news-6.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-5.jpg",
            title: "ラオスニュース No.5",
            link: "/images/laos-news/laos-news-5.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-4.jpg",
            title: "ラオスニュース No.4",
            link: "/images/laos-news/laos-news-4.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-3.jpg",
            title: "ラオスニュース No.3",
            link: "/images/laos-news/laos-news-3.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-2.jpg",
            title: "ラオスニュース No.2",
            link: "/images/laos-news/laos-news-2.pdf",
        },
        {
            thumbnail: "images/laos-news/laos-news-thumbnail-1.jpg",
            title: "ラオスニュース No.1",
            link: "/images/laos-news/laos-news-1.pdf",
        },
    ];

    return (
        // ニュース一覧を表示
        //  ページのタイトルは左上に表示
        // 日時、タイトルを表示
        // linkで囲って、クリックすると詳細ページに飛ぶ
        <div className="base-margin">
            <h1 className="text-4xl font-bold text-start my-8">会報誌</h1>
            <div className="flex flex-col justify-center items-center">
                <div className="m-4 mb-0 flex flex-wrap w-full gap-2 md:gap-8 justify-evenly">
                    {/* <Link to={"pdf"} className="w-36 p-2 hover:bg-gray-100">
                        <img
                            className="mb-0 onbject-cover"
                            src="https://placehold.jp/400x500.jpg"
                        />
                        <p>タイトル</p>
                    </Link> */}
                    {/* 上記をarticlesから生成 */}
                    {articles.map((article) => (
                        <a
                            href={article.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="w-full md:w-60 p-2 mb-8 hover:bg-gray-100"
                        >
                            <img
                                className="mb-2 onbject-cover border"
                                src={article.thumbnail}
                                alt={article.title}
                            />
                            <p className="w-full text-center text-sm">
                                {article.title}
                            </p>
                        </a>
                    ))}
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                    <div className="w-full md:w-60 h-0"></div>
                </div>
            </div>
        </div>
    );
};

export default LaosPage;
