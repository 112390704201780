import React from "react";
import AssociationMenu from "../templates/sidemenu/AssociationMenu";
const OfficerPage: React.FC = () => {
    const advisers = [
        {
            position: "名誉会長",
            organization: "駐日ラオス人民民主共和国特命全権大使",
            name: "フォンサムット・アンラワン",
        },
        {
            position: "名誉副会長",
            organization: "在福岡ラオス人民民主共和国名誉領事",
            name: "河邉 哲司",
        },
        {
            position: "顧問",
            organization: "参議院議員",
            name: "松山 政司",
        },
        {
            position: "顧問",
            organization: "経済産業省 九州経済産業局長",
            name: "苗村 公嗣",
        },
        {
            position: "顧問",
            organization: "福岡県知事",
            name: "服部 誠太郎",
        },
        {
            position: "顧問",
            organization: "福岡市長",
            name: "高島 宗一郎",
        },
        {
            position: "顧問",
            organization: "福岡商工会議所会頭",
            name: "谷川 浩道",
        },
        {
            position: "顧問",
            organization: "（一社）九州経済連合会 会長",
            name: "倉富 純男",
        },
        {
            position: "相談役",
            organization: "津田産業㈱ 代表取締役会長",
            name: "津田 悦夫",
        },
    ];

    const officers = [
        {
            position: "会長",
            organization: "㈱福岡ニット 代表取締役社長",
            name: "小林 敏郎",
        },
        {
            position: "副会長",
            organization: "九州電力㈱ 国際事業室 執行役員室長",
            name: "落合 智之",
        },
        {
            position: "副会長",
            organization: "中牟田大郷㈲ 代表取締役",
            name: "中牟田 万平",
        },
        {
            position: "副会長",
            organization: "㈱シグマイン 代表取締役会長",
            name: "村尾 忠孝",
        },
        {
            position: "理事",
            organization: "㈱正興電機製作所 代表取締役会長",
            name: "土屋 直知",
        },
        {
            position: "理事",
            organization: "中村工業㈱ 会長",
            name: "中村 隆輔",
        },
        {
            position: "理事",
            organization: "西研グラフィックス㈱ 相談役",
            name: "並田 正一",
        },
        {
            position: "理事",
            organization: "三菱商事㈱ 理事九州支社長",
            name: "山崎 仁",
        },
        {
            position: "理事",
            organization: "㈱光栄テクノシステム 代表取締役社長",
            name: "安武 良祐",
        },
        {
            position: "理事",
            organization: "三井物産㈱ 理事九州支社長",
            name: "中村 隆三",
        },
        {
            position: "理事",
            organization: "（一社）九州経済連合会 専務理事",
            name: "平井 彰",
        },
        {
            position: "理事",
            organization: "津田産業㈱ 代表取締役社長",
            name: "津田 鶴太郎",
        },
        {
            position: "理事",
            organization: "ラオスＫＩＤＳねっと推進協議会",
            name: "前田 豊",
        },
        {
            position: "理事",
            organization: "エイケン㈱ 専務取締役",
            name: "橋本 冨喜子",
        },
        {
            position: "理事",
            organization: "那の香ライオンズクラブ",
            name: "末松 和子",
        },
        {
            position: "理事",
            organization: "岡部機械工業㈱ 代表取締役副社長",
            name: "岡田 修三",
        },
        {
            position: "理事",
            organization: "（公社）福岡貿易会 専務理事",
            name: "平塚 伸也",
        },
        {
            position: "理事・事務局長",
            organization:
                "（公社）福岡貿易会 特別顧問、在福岡ラオス名誉領事館名誉領事補佐官",
            name: "甲斐 敏洋",
        },
        {
            position: "監事",
            organization: "太平洋貿易㈱ 会長",
            name: "田嶋 猛",
        },
    ];
    // ユーザーの画面サイズを取得
    const [userView, setUserView] = React.useState(
        sessionStorage.getItem("userView")
    );

    if (userView === "") {
        return <></>;
    }
    return (
        // メンバーページはメンバーの情報を表示する
        // タイトル
        // 役員と法人会員の情報
        <div className="base-margin">
            {/* sp版の方では<AssociationMenu />を表示 */}
            {userView === "sp" && <AssociationMenu />}
            <div className="flex flex-col md:flex-row my-8">
                <h1 className="text-4xl font-bold text-start ">役員一覧</h1>
                <div className="text-2xl font-medium">(令和5年度版)</div>
            </div>
            <div className="flex flex-row justify-between gap-26 w-full">
                {/* pc版の方では<AssociationMenu />を表示 */}
                {userView === "pc" && <AssociationMenu />}
                <div className="w-full md:mx-8">
                    <table className="border border-secondary  w-full mb-8">
                        <thead className="border text-lg font-bold">
                            <tr className="border">
                                <td
                                    className="py-2 text-start pl-2"
                                    colSpan={3}
                                >
                                    名誉会長・顧問等
                                </td>
                            </tr>
                            <tr className="border">
                                <th className="p-2 border text-start w-16">
                                    役職
                                </th>
                                <th className="p-2 border text-start w-60">
                                    団体・職名
                                </th>
                                <th className="p-2 border text-start w-24">
                                    名前
                                </th>
                            </tr>
                        </thead>
                        <tbody className="border">
                            {/*  advisersから生成 */}
                            {advisers.map((adviser) => (
                                <tr className="border">
                                    <td className="p-2 border text-start">
                                        {adviser.position}
                                    </td>
                                    <td className="p-2 border text-start">
                                        {adviser.organization}
                                    </td>
                                    <td className="p-2 border text-start">
                                        {adviser.name}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <table className="border border-secondary  w-full mb-8">
                        <thead className="border text-lg font-bold">
                            <tr className="border">
                                <td
                                    className="py-2 text-start pl-2"
                                    colSpan={3}
                                >
                                    役員
                                </td>
                            </tr>
                            <tr className="border">
                                <th className="p-2 border text-start w-16">
                                    役職
                                </th>
                                <th className="p-2 border text-start w-60">
                                    団体・職名
                                </th>
                                <th className="p-2 border text-start w-24">
                                    名前
                                </th>
                            </tr>
                        </thead>
                        <tbody className="border">
                            {/* officersから生成 */}
                            {officers.map((officer) => (
                                <tr className="border">
                                    <td className="p-2 border text-start">
                                        {officer.position}
                                    </td>
                                    <td className="p-2 border text-start">
                                        {officer.organization}
                                    </td>
                                    <td className="p-2 border text-start">
                                        {officer.name}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default OfficerPage;
