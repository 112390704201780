import React from "react";
import { Link } from "react-router-dom";
const Article3: React.FC = () => {
    return (
        // ニュース一覧を表示
        //  ページのタイトルは左上に表示
        // 日時、タイトルを表示
        // linkで囲って、クリックすると詳細ページに飛ぶ
        <div className="base-margin">
            <div className="my-8">
                <h1 className="text-2xl md:text-4xl font-bold text-start">
                    微笑みの国・ラオスセミナーについて。
                </h1>
                <p className="text-sx text-start text-gray-500">2023/07/03</p>
            </div>
            <div className="mx-4 md:mx-16 text-base">
                <p>
                    時下、益々ご清栄のこととお慶び申し上げます。
                    <br />
                    福岡・ラオス友好協会の運営につきましては日頃よりご支援・ご協力をいただき、まことにありがとうございます。
                    <br />
                    さて、この度福岡・ラオス友好協会４年ぶりのラオスセミナーと交流会を開催いたしますので、ご案内申し上げます。
                    <br />
                    詳細については、下記のPDFをご覧ください。
                </p>
                <div className="flex flex-col justify-center items-center mt-16">
                    <a
                        href="/images/news/微笑みの国・ラオスセミナーについて.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            PDFをダウンロード
                        </button>
                    </a>
                </div>
            </div>
            <div className="mt-8 flex justify-center">
                <Link
                    to={"/news"}
                    className="text-lg w-fit text-center py-2 border-b hover:bg-gray-100"
                >
                    <p>一覧に戻る</p>
                </Link>
            </div>
        </div>
    );
};

export default Article3;
