import React from "react";
import { Link } from "react-router-dom";
function Footer() {
    return (
        <>
            <footer className="text-disable bg-main body-font w-full mt-12 bottom-0 px-8 z-40">
                <div className="container h-full pt-4 lg:pt-12 lg:pb-8 mx-auto justify-between items-center flex lg:items-start lg:flex-row lg:flex-nowrap flex-wrap">
                    {/* 会社名とロゴ、連絡先、住所を配置する */}
                    {/* 左側に会社名とロゴ  その下に住所と連絡先*/}
                    {/* 真ん中と右側にサイトマップ*/}
                    <div className="flex-grow flex flex-col lg:items-start lg:text-left items-center text-center break-keep">
                        <Link
                            to="/"
                            className="text-2xl font-bold mb-4 w-full font-medium text-white flex gap-1 items-center"
                        >
                            <img
                                src="/images/top-image/Laos.png"
                                className="w-4 object-contain"
                                alt="ラオスの国旗"
                            />
                            福岡ラオス友好協会
                            <img
                                src="/images/top-image/Japan.png"
                                className="w-4 object-contain"
                                alt="日本の国旗"
                            />
                        </Link>
                        <p className="mb-4 md:mb-2 leading-relaxed w-full text-white break-keep">
                            {/* 〒810-0001
                            <br />
                            福岡県福岡市中央区天神1丁目1
                            <br />
                            天神イグザンプルビル3階
                            <br /> */}
                            TEL:090-1191-9539
                            <br />
                            FAX:092-577-3939
                            <br />
                            <a
                                href="toshihiro.kai3939@gmail.com"
                                className="text-white"
                            >
                                MAIL:toshihiro.kai3939@gmail.com
                            </a>
                        </p>
                    </div>
                    <div className="flex-grow text-sx md:text-base flex flex-col md:flex-row lg:items-start lg:text-left items-start md:items-center text-center w-full justify-center mb-4 md:mb-0 gap-2 md:gap-8">
                        <div className="flex flex-col">
                            <Link
                                to="/association"
                                className="text-base md:text-lg text-white font-bold"
                            >
                                協会について
                            </Link>
                            <Link
                                to="/rule"
                                className="ml-2 text-white hidden md:block"
                            >
                                会則
                            </Link>
                            <Link
                                to="/officer"
                                className="ml-2 text-white hidden md:block"
                            >
                                役員一覧
                            </Link>
                            <Link
                                to="/member"
                                className="ml-2 text-white hidden md:block"
                            >
                                会員一覧
                            </Link>
                            <Link
                                to="/plan"
                                className="ml-2 text-white hidden md:block"
                            >
                                事業計画
                            </Link>
                            <Link
                                to="/report"
                                className="ml-2 text-white hidden md:block"
                            >
                                事業報告
                            </Link>
                        </div>
                        <Link
                            to="/news"
                            className="text-base md:text-lg text-white font-bold"
                        >
                            お知らせ
                        </Link>
                        <Link
                            to="/article"
                            className="text-base md:text-lg text-white font-bold"
                        >
                            会報誌
                        </Link>
                        <Link
                            to="/laos"
                            className="text-base md:text-lg text-white font-bold"
                        >
                            ラオスについて
                        </Link>
                        <Link
                            to="/ten-years"
                            className="text-base md:text-lg text-white font-bold"
                        >
                            １０年のあゆみ
                        </Link>
                    </div>
                </div>
            </footer>
            {/* コピーライト */}
            <div className="bg-secondary text-white text-left p-4">
                <p className="text-[0.5rem] md:text-sm">
                    © Fukuoka Laos Friendship Association. All Rights Reserved.
                </p>
            </div>
        </>
    );
}
export default Footer;
