import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/*",
        element: <App />,
    },
]);
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<RouterProvider router={router} />);
