import React from "react";
import { Link } from "react-router-dom";
function AssociationMenu() {
    // sp版の場合おりたためるようにする
    const [isShow, setIsShow] = React.useState(
        sessionStorage.getItem("isShow") === "true"
    );

    const OnClickAssociationMenu = () => {
        if (sessionStorage.getItem("userView") === "sp") {
            sessionStorage.setItem(
                "isShow",
                sessionStorage.getItem("isShow") === "true" ? "false" : "true"
            );
            setIsShow(!isShow);
        }
    };

    return (
        //  サイドメニューは画面の左側に固定する
        // 高さは画面50%で、上下中央に配置する
        // メニューの項目はホーム、事業計画、事業報告、会員募集、会員一覧、会員登録、ログイン、ログアウト
        // メニューの項目はボタンっぽくする
        // メニューの項目は縦に並べる
        // テキストは白色にする

        <div className=" flex flex-col  w-full md:w-1/6">
            <div
                className="my-2 flex justify-between border-b-2"
                onClick={() => OnClickAssociationMenu()}
            >
                <p className="text-xl font-bold p-1  pr-8">協会情報</p>
                {sessionStorage.getItem("userView") === "sp" && (
                    <div className="flex justify-center items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className={`h-6 w-6 transition-transform ${
                                isShow ? "rotate-[0deg]" : "rotate-[-90deg]"
                            }`}
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                        </svg>
                    </div>
                )}
            </div>
            <div
                className={`flex flex-col gap-2 text-sm md:text-lg overflow-hidden ${
                    isShow ? "h-80" : "h-0"
                } transition-height duration-500 ease-in-out`}
            >
                <Link
                    to={"/association"}
                    className="ml-4 text-lg grid grid-flow-col-dense grid-cold-4 py-2 gap-4 border-b hover:bg-gray-100"
                >
                    <p>協会について</p>
                </Link>
                <Link
                    to={"/rule"}
                    className="ml-4 text-lg grid grid-flow-col-dense grid-cold-4 py-2 gap-4 border-b hover:bg-gray-100"
                >
                    <p>会則</p>
                </Link>
                <Link
                    to={"/officer"}
                    className="ml-4 text-lg grid grid-flow-col-dense grid-cold-4 py-2 gap-4 border-b hover:bg-gray-100"
                >
                    <p>役員一覧</p>
                </Link>
                <Link
                    to={"/member"}
                    className="ml-4 text-lg grid grid-flow-col-dense grid-cold-4 py-2 gap-4 border-b hover:bg-gray-100"
                >
                    <p>会員一覧</p>
                </Link>
                <Link
                    to={"/plan"}
                    className="ml-4 text-lg grid grid-flow-col-dense grid-cold-4 py-2 gap-4 border-b hover:bg-gray-100"
                >
                    <p>事業計画</p>
                </Link>
                <Link
                    to={"/report"}
                    className="ml-4 text-lg grid grid-flow-col-dense grid-cold-4 py-2 gap-4 border-b hover:bg-gray-100"
                >
                    <p>事業報告</p>
                </Link>
            </div>
        </div>
    );
}
export default AssociationMenu;
