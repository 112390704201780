import React from "react";
import AssociationMenu from "../templates/sidemenu/AssociationMenu";
const RulePage: React.FC = () => {
    // ユーザーの画面サイズを取得
    const [userView, setUserView] = React.useState(
        sessionStorage.getItem("userView")
    );

    if (userView === "") {
        return <></>;
    }
    return (
        // ルールページは会則を表示する

        // タイトル
        // 会則
        <div className="base-margin">
            {/* sp版の方では<AssociationMenu />を表示 */}
            {userView === "sp" && <AssociationMenu />}
            <h1 className="text-4xl font-bold text-start my-8">
                福岡ラオス友好協会会則
            </h1>
            <div className="flex flex-row justify-between gap-16 w-full">
                {/* pc版の方では<AssociationMenu />を表示 */}
                {userView === "pc" && <AssociationMenu />}
                <div className="w-full">
                    <div className="ml-8">
                        <div className="mb-4">
                            <p className="text-md font-bold">(名称)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第1条</p>
                                <p className="w-full md:w-11/12">
                                    本会は福岡ラオス友好協会と称する。
                                </p>
                            </div>
                        </div>

                        <div className="mb-4">
                            <p className="text-md font-bold">(目的)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第2条</p>
                                <p className="w-full md:w-11/12">
                                    本会は、ラオスとの経済、文化交流を通じて、両国の友好親善を図り、両国の発展に寄与することを目的とする。
                                </p>
                            </div>
                        </div>

                        <div className="mb-4">
                            <p className="text-md font-bold">(活動)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第3条</p>
                                <p className="w-full md:w-11/12">
                                    本会は、前条の目的を達成するため、次の活動を行う。
                                </p>
                            </div>
                            {/* 1)のように表示するものはml-4 md:ml-12をつける */}
                            <p className="ml-4 md:ml-12">
                                1) ラオスとの経済、文化交流事業の企画、実施
                            </p>
                            <p className="ml-4 md:ml-12">
                                2) 九州のラオス留学生との交流支援
                            </p>
                            <p className="ml-4 md:ml-12">
                                3)
                                来福したラオス要人との交流、及びラオスセミナーの開催
                            </p>
                            <p className="ml-4 md:ml-12">
                                4) 会員へのラオス情報の提供
                            </p>
                            <p className="ml-4 md:ml-12">
                                5) 現地教育資材の提供等による教育水準向上支援
                            </p>
                            <p className="ml-4 md:ml-12">
                                6) その他、本会の目的に必要な事業
                            </p>
                        </div>
                        <div className="mb-4">
                            <p className="text-md font-bold">(会員)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第4条</p>
                                <p className="w-full md:w-11/12">
                                    会の目的に賛同する個人及び法人(団体)は会員となることができる。
                                </p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <p className="text-md font-bold">(会費)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第5条</p>
                                <p className="w-full md:w-11/12">
                                    会員は次に定める費用を納めなければならない。
                                </p>
                            </div>
                            <p className="ml-4 md:ml-12">
                                1) 法人(団体)会員 1口 1万円 1口以上
                            </p>
                            <p className="ml-4 md:ml-12">
                                2) 個人会員 1口 5,000円 1口以上
                            </p>
                        </div>
                        <div>
                            <p className="text-md font-bold">(役員)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第6条</p>
                                <p className="w-full md:w-11/12">
                                    本会は、次の役員を置くこととし、会員の中から総会で選出する。
                                </p>
                            </div>
                        </div>

                        <div className="mb-4">
                            <p className="ml-4 md:ml-12">1) 会長 1名</p>
                            <p className="ml-16">
                                会長は、本会を代表し、本会の活動を統括する。
                            </p>
                            <p className="ml-4 md:ml-12">2) 副会長 若干名</p>
                            <p className="ml-16">
                                副会長は、会長を補佐し、会長に事故あるときは会長に代わって其の職務を代行する。
                            </p>
                            <p className="ml-4 md:ml-12">3) 理事 若干名</p>
                            <p className="ml-16">
                                理事は会の運営にかかわる業務を行う。理事のうち1名は事務局長を兼務する。
                            </p>
                            <p className="ml-4 md:ml-12">4) 監事</p>
                            <p className="ml-16">
                                監事は、本会の会計を監査する。
                            </p>
                        </div>
                        <div className="mb-4">
                            <p className="text-md font-bold">
                                (名誉会長・顧問等)
                            </p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第7条</p>
                                <p className="w-full md:w-11/12">
                                    本会に、名誉会長、名誉副会長、顧問、アドバイザー(以下「顧問等」という)を置くことができる。
                                    顧問等は、理事会の同意を得て、会長が委嘱する。
                                </p>
                            </div>
                        </div>

                        <div className="mb-4">
                            <p className="text-md font-bold">(総会)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第8条</p>
                                <p className="w-full md:w-11/12">
                                    総会は、年一回以上開催し、協会の予算・決算及び事業計画・役員選出などの重要事項について審議する。
                                </p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <p className="text-md font-bold">(役員会)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第9条</p>
                                <p className="w-full md:w-11/12">
                                    役員会は、必要に応じて開催し、本会の重要事項について審議決定する。
                                </p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <p className="text-md font-bold">(会計)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第10条</p>
                                <p className="w-full md:w-11/12">
                                    本会の会計年度は、毎年4月1日より翌年3月31日までの1年とする。
                                </p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <p className="text-md font-bold">(事務局)</p>
                            <div className="flex flex-col md:flex-row">
                                <p className="w-full md:w-1/12">第11条</p>
                                <p className="w-full md:w-11/12">
                                    本会は、事務局を設置し、事務局長を置く。事務局長は、理事のうち1名が兼務する。
                                </p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <p>付 則</p>
                            <p>本会則は、2010年4月5日より発効する。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RulePage;
