import React from "react";
import { Link } from "react-router-dom";
import AssociationMenu from "../templates/sidemenu/AssociationMenu";
const ReportPage: React.FC = () => {
    const reports = [
        {
            name: "2022年度(令和4年)事業報告",
            pdf: "/images/reports/report-r4.pdf",
        },
        {
            name: "2021年度(令和3年)事業報告",
            pdf: "/images/reports/report-r3.pdf",
        },
        {
            name: "2020年度(令和2年)事業報告",
            pdf: "/images/reports/report-r2.pdf",
        },
    ];

    // ユーザーの画面サイズを取得
    const [userView, setUserView] = React.useState(
        sessionStorage.getItem("userView")
    );

    if (userView === "") {
        return <></>;
    }
    return (
        // 事業報告を表示するページ
        <div className="base-margin">
            {/* sp版の方では<AssociationMenu />を表示 */}
            {userView === "sp" && <AssociationMenu />}
            <h1 className="text-4xl font-bold text-start my-8">事業報告</h1>
            <div className="flex flex-row justify-between gap-16 w-full">
                {/* pc版の方では<AssociationMenu />を表示 */}
                {userView === "pc" && <AssociationMenu />}
                <div className="flex flex-col justify-center items-center w-full">
                    <div className="m-4 flex flex-col w-full">
                        {reports.map((report, i) => (
                            <div
                                className="mx-2 border-b border-t py-6 flex gap-4 md:gap-32 items-center"
                                key={i}
                            >
                                <p className="text-lg">{report.name}</p>
                                <a
                                    href={report.pdf}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-blue-500 hover:bg-blue-700 text-white text-sm md:text-base font-bold py-2 px-4 rounded"
                                >
                                    PDFダウンロード
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportPage;
